<template>
    <section class="media-uploader">
        <section
            v-if="files.length === 0"
            id="drop-area"
            :class="{ 'highlight': highlight }"
            v-on:dragenter.stop.prevent="highlight = true"
            v-on:dragover.stop.prevent="highlight = true"
            v-on:dragleave.stop.prevent="highlight = false"
            v-on:drop.stop.prevent="highlight = false; handleDrop($event)"
        >

            <img
                v-show="highlight"
                class="media-uploader__image"
                :src="require('../../../assets/uploader/media-library-upload-highlight.png')"
            />
            <img
                v-show="!highlight"
                class="media-uploader__image"
                :src="require('../../../assets/uploader/media-library-upload.png')"
            />

            <h2 class="media-uploader__title">Drag & Drop</h2>
            <p class="media-uploader__or-label">or</p>
            <form class="my-form">
                <input
                    type="file"
                    id="file-upload"
                    ref="fileUpload"
                    multiple
                    @change="grabImages($event)"
                    accept="image/*,.pdf"
                >
            </form>

            <div class="media-uploader__notice">
                <p class="media-uploader__notice-size">Max file size <b>4MB</b></p>
                <p class="media-uploader__notice-files">Accepted Files <br>.jpg .png .pdf .gif</p>
            </div>
        </section>

        <transition name="fade">
            <section
                v-if="files.length > 0"
                class="upload-preview"
            >
                <h2 class="upload-preview__title">{{ files.length }} <span>Images Ready to Upload</span></h2>

                <div class="upload-preview__list">
                    <div
                        v-for="(file, index) of files"
                        class="upload-preview__item"
                        :key="`file-${index}`"
                    >
                        <div
                            v-if="file.originalType === 'application/pdf'"
                            class="upload-preview__image upload-preview__image--pdf"
                        >
                        </div>
                        <img
                            v-else
                            :src="`data:image/jpeg;base64,${file.image}`"
                            class="upload-preview__image"
                        />

                        <div class="upload-preview__fields">
                            <p
                                v-if="uploadStatus[index] === 'uploading'"
                                class="upload-preview__bar--inprogress"
                            >Uploading</p>
                            <template v-if="uploadingComplete">
                                <p
                                    v-if="uploadStatus[index] === 'success'"
                                    class="upload-preview__bar--success"
                                >Upload Successful</p>
                                <p
                                    v-else-if="uploadStatus[index] === 'failed'"
                                    class="upload-preview__bar--failed"
                                >Upload Failed</p>
                            </template>

                            <label
                                :for="`filename-${index}`"
                                class="upload-preview__label"
                            >Filename</label>
                            <input
                                :name="`filename-${index}`"
                                v-model="file.name"
                                class="upload-preview__input"
                            />
                            <!-- <label :for="`filealt-${index}`" class="upload-preview__label">Alt text</label>
              <input :name="`filealt-${index}`" v-model="file.altTag"  class="upload-preview__input" /> -->
                            <label
                                :for="`filecaption-${index}`"
                                class="upload-preview__label"
                            >Caption</label>
                            <input
                                :name="`filecaption-${index}`"
                                v-model="file.description"
                                class="upload-preview__input"
                            />

                            <p class="upload-preview__meta">Info
                                <span>{{ file.originalType }}</span>
                                <span>{{ formatBytes(file.originalSize) }}</span>
                                <span>Modified {{ file.lastModified | moment(('DD/MM/YY kk:mm')) }}</span>
                            </p>

                            <v-btn
                                v-if="imageSelector && file.uploadStatus === 'success'"
                                color="accent"
                                @click="chooseImage(file.mediaLibraryID, file.imageKey)"
                            >Select image</v-btn>
                        </div>
                    </div>
                </div>
            </section>
        </transition>


        <div
            v-if="uploadingComplete"
            class="upload-actions"
        >
            <div class="upload-actions__group-label">
                <p
                    v-if="failTotal"
                    class="upload-actions__label upload-actions__label--error"
                >{{ failTotal }} <span>file(s) failed to upload</span></p>
                <p class="upload-actions__label">{{ successTotal }} <span>file(s) uploaded successfully</span></p>
            </div>

            <button
                class="upload-actions__folder"
                @click="openDirectory()"
            ><v-icon class="upload-actions__folder-icon">folder</v-icon>Open folders</button>

            <v-btn
                @click="restart()"
                color="accent"
                class="upload-actions__primary"
            >Upload more</v-btn>
        </div>

        <div
            v-else-if="files.length > 0"
            class="upload-actions"
        >
            <div class="upload-actions__group-label">
                <p class="upload-actions__label">{{ files.length }} <span>file(s) to upload</span></p>
                <p
                    class="upload-actions__label"
                    @click="restart()"
                ><span>Choose images again</span></p>
            </div>

            <div class="">
                <p class="upload-actions__location">Adding to <span class="upload-actions__location-folder"><v-icon
                            class="upload-actions__location-icon"
                        >folder</v-icon>{{ targetDirectoryName || $store.state.mediaLibrary.directoryName }}</span></p>
                <subdirectory-selector @changeDirectory="updateDirectory($event)">
                    <button class="upload-actions__folder"><v-icon class="upload-actions__folder-icon">perm_media</v-icon>Change folder</button>
                </subdirectory-selector>
            </div>

            <v-btn
                @click="uploadAllFiles()"
                color="accent"
                class="upload-actions__primary"
            >Upload</v-btn>
        </div>

    </section>
</template>

<script>
import axios from "axios"
import SubdirectorySelector from "@/components/Media/V2/SubdirectorySelector.vue"

export default {
    name: "MediaUploader",

    components: {
        SubdirectorySelector
    },

    props: {
        imageSelector: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        files: [],
        uploading: false,
        uploadingComplete: false,
        uploadStatus: [],
        highlight: false,
        successTotal: 0,
        failTotal: 0,
        targetDirectoryID: null,
        targetDirectoryName: null
    }),

    methods: {
        restart() {
            this.files = []
            this.uploading = false
            this.uploadingComplete = false
            this.uploadStatus = []
            this.highlight = false
            this.successTotal = 0
            this.failTotal = 0
        },

        openDirectory() {
            this.$emit("openDirectory")
        },

        updateDirectory(payload) {
            console.log("updateDirectory")
            for (const item in this.files) {
                this.files[item].mediaDirectoryID = payload.directoryID
            }

            this.targetDirectoryID = payload.directoryID
            this.targetDirectoryName = payload.directoryName
        },

        handleDrop($event) {
            const dt = $event.dataTransfer
            const files = dt.files

            console.log("dt", dt)
            console.log("files", files)

            for (const file of files) {
                this.convertImage(file)
            }
        },

        grabImages($event) {
            for (const file of $event.target.files) {
                this.convertImage(file)
            }
        },

        convertImage(file) {
            console.log("Converting", file)

            const reader = new FileReader();

            reader.addEventListener("load", evt => {
                console.log("reader", reader);

                const base64DataOnly = reader.result.split(",")[1].toString();

                this.files.push({
                    mediaDirectoryID: this.$store.state.mediaLibrary.directoryID,
                    image: base64DataOnly,
                    convertedSize: this.calculateImageSize(reader.result),
                    name: file.name,
                    description: "",
                    altTag: "",
                    originalSize: file.size,
                    originalType: file.type,
                    lastModified: file.lastModified,
                    tags: [],
                })
            });

            if (file) {
                reader.readAsDataURL(file);
            }
        },

        uploadAllFiles() {
            this.$store.commit("startLoading");
            console.log("Uploading all files: ", this.files)
            this.uploading = true

            const promises = [];

            let index = 0;
            for (const file of this.files) {
                console.log(`Pushing file ${index} : ${file}`)
                this.uploadStatus.push("")

                promises.push(
                    this.uploadFile(file, index)
                )

                index++
            }

            Promise.all(promises).then(() => {
                console.log("All files uploaded")
                this.$store.commit("completeLoading");
                this.countSuccess()
                this.uploading = false
                this.uploadingComplete = true
                console.log("uploadStatus", this.uploadStatus)
            }).catch(() => {
                alert("There was an issue with one or more files")
                this.$store.commit("completeLoading");
                this.countSuccess()
                this.uploading = false
                this.uploadingComplete = true
                console.log("uploadStatus", this.uploadStatus)
            })
        },

        uploadFile(file, index) {
            return new Promise((resolve, reject) => {
                console.log(`${file.name} uploading`)

                this.uploadStatus[index] = "uploading"

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "POST",
                        url: `${process.env.VUE_APP_BASEURL}/media/upload`,
                        headers: { "Authorization": jwt },
                        data: file
                    })
                        .then(response => {
                            console.log("Uploaded: ", response);
                            this.files[index].imageKey = response.data.body.key
                            this.files[index].mediaLibraryID = response.data.body.mediaLibraryID
                            this.files[index].uploadStatus = "success"
                            this.uploadStatus[index] = "success"

                            setTimeout(() => {
                                resolve("Upload file complete")
                            }, 100)
                        })
                        .catch(e => {
                            console.error("Problem saving image: ", e);

                            this.uploadStatus[index] = "failed"
                            this.files[index].uploadStatus = "failed"

                            setTimeout(() => {
                                reject("Upload file failed:", e)
                            }, 100)

                        });
                })
            })
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },

        calculateImageSize(base64String) {
            let padding;
            let inBytes = null;
            let base64StringLength = 0;
            if (base64String.endsWith("==")) { padding = 2; }
            else if (base64String.endsWith("=")) { padding = 1; }
            else { padding = 0; }

            base64StringLength = base64String.length;
            inBytes = (base64StringLength / 4) * 3 - padding;

            return this.formatBytes(inBytes);
        },

        countSuccess() {
            for (const item of this.uploadStatus) {
                if (item === "success") {
                    this.successTotal = this.successTotal + 1
                } else {
                    this.failTotal = this.failTotal + 1
                }
            }
        },

        chooseImage(mediaLibraryID, imageKey) {
            console.log("chooseImage", mediaLibraryID, imageKey)

            this.$store.commit("selectImage", {
                mediaLibraryID,
                imageKey
            });
        }
    }
}
</script>

<style scoped lang="scss">
#drop-area {
    border: 2px dashed #dfe3e9;
    border-radius: 20px;
    width: calc(100% - 64px);
    font-family: sans-serif;
    margin: 32px;
    padding: 20px;
}

#drop-area.highlight {
    border: solid 2px #67247c;

    animation-duration: 0.6s;
    animation-name: borderHover;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.media-uploader {
    display: block;
    margin: 16px 0 16px 16px;
    min-height: 400px;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #dfe3e9;
    background-color: #fafafa;

    &__image {
        display: block;
        margin: 0 auto;
        width: 260px;
        pointer-events: none;
    }

    &__title {
        color: #24002f;
        font-family: 'Rubik', sans-serif;
        font-size: 32px;
        font-weight: 500;
    }

    &__or-label {
        color: #7b6b80;
        font-size: 16px;
        font-weight: bold;
    }

    &__notice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 60px 16px 16px;
        padding: 11px 32px 13px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        background-color: #ffffff;
    }

    &__notice-size {
        margin: 0;
        padding: 0;
        color: #24002f;
        font-size: 16px;
        text-align: left;
    }

    &__notice-files {
        margin: 0;
        padding: 0;
        color: #7b6b80;
        font-size: 16px;
        font-style: italic;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
    }
}

.upload-preview {
    // position: absolute;
    // top: 16px;
    // right: 0;
    // bottom: 0;
    // left: 16px;
    padding: 16px 16px 56px;
    // overflow-y: scroll;
    z-index: 2;
    text-align: left;
    background-color: #fafafa;
    border-radius: 4px;
    border: solid 1px #dfe3e9;

    &__title {
        margin: 0 0 8px 8px;
        padding: 0;
        color: #24002f;
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-weight: 500;

        span {
            font-weight: 400;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 32px;
        padding: 16px;
        background-color: #ffffff;
        border: solid 1px #dfe3e9;
        border-radius: 4px;
    }

    &__image {
        height: auto;
        width: 280px;
        border-radius: 4px;

        &--pdf {
            background: transparent url('../../../assets/file.svg') no-repeat left top;
            height: 120px;
        }
    }

    &__fields {
        padding: 0 0 0 16px;
        width: 100%;
    }

    &__input {
        padding: 7px 8px 6px 8px;
        margin: 0 0 16px;
        width: 100%;
        color: #24002f;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: italic;
        background-color: #fafafa;
        border-radius: 2px;
        border: solid 1px #dfe3e9;
    }

    &__label {
        display: block;
        margin: 0 0 3px;
        padding: 0;
        color: #7b6b80;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        line-height: 1;
    }

    &__meta {
        display: block;
        margin: 0 0 3px;
        padding: 0;
        color: #7b6b80;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        line-height: 1;

        span {
            display: inline-block;
            padding: 2px 4px 1px;
            margin: 4px;
            font-size: 10px;
            font-style: italic;
            border: 1px solid;
            border-radius: 3px;
        }
    }

    &__bar--inprogress,
    &__bar--success,
    &__bar--failed {
        margin: 0 0 16px;
        padding: 4px 8px 2px;
        width: 100%;
        color: #fff;
        font-size: 10px;
        text-align: left;
        background-color: #ccc;
        border-radius: 4px;
    }

    &__bar--inprogress {
        position: relative;
        background-color: #f1a165;
        background-image: linear-gradient(to bottom, #f1a165, #f36d0a);

        animation-duration: 0.6s;
        animation-name: loadingBorder;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &__bar--success {
        background-color: rgb(43, 194, 83);
        background-image: linear-gradient(center bottom,
                rgb(43, 194, 83) 37%,
                rgb(84, 240, 84) 69%);
    }

    &__bar--failed {
        background-color: #f0a3a3;
        background-image: linear-gradient(to bottom, #f0a3a3, #f42323);

        animation-duration: 0.6s;
        animation-name: loadingBorderFailed;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.upload-actions {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 26px;
    z-index: 10;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);

    &__label {
        margin: 0 16px 0 0;
        padding: 0;
        color: #24002f;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: left;

        span {
            font-weight: 400;
        }

        &--error {
            color: #f42323;
        }
    }

    &__folder {
        padding: 0;
        margin: 0 16px;
        opacity: 0.5;
        color: #516173;
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-weight: 500;
    }

    &__folder-icon {
        position: relative;
        top: 2px;
        padding: 0 4px 0 0;
    }

    &__location {
        margin: 0 0 10px;
        padding: 0;
        color: #516173;
        font-family: 'Rubik', sans-serif;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        opacity: 0.5;
    }

    &__location-folder {
        border-bottom: 1px solid;
        padding: 3px;
        border-radius: 4px;
    }

    &__location-icon {
        padding: 0 3px 0 0;
        color: #516173;
        font-size: 16px;
    }
}

@keyframes borderHover {
    0% {
        border-color: #67247c;
    }

    100% {
        border-color: #7b6b80;
    }
}

@keyframes loadingBorder {
    0% {
        box-shadow: 0px 0px 1px 0px #f36d0a;
    }

    100% {
        box-shadow: 0px 0px 6px 0px #f36d0a;
    }
}

@keyframes loadingBorderFailed {
    0% {
        box-shadow: 0px 0px 1px 0px #f36d0a;
    }

    100% {
        box-shadow: 0px 0px 6px 0px #f42323;
    }
}
</style>
