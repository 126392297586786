<template>
  <section class="clipboard">
    <p class="clipboard__label">Selected</p>
    <article class="clipboard__item">
      <img class="clipboard__image" :src="`${imageURL}/${$store.state.mediaLibrary.selectedImage.imageKey}`" />
    </article>
  </section>
</template>

<script>
export default {
  name: "ClipboardPreview",

  data:() => ({
    imageURL: process.env.VUE_APP_IMAGEURL,
  })
}
</script>

<style scoped lang="scss">
  .clipboard {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 4px 8px 0;
    background: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.24);
    transform: translateX(-50%);
    z-index: 10;

    &__label {
      margin: 0;
      padding: 0;
      opacity: 0.5;
      color: #516173;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__item {
      min-width: 80px;

      &:hover {
        .clipboard__image {
          height: 400px;
        }
      }
    }

    &__image {
      display: block;
      height: 80px;
      margin: 0 auto 8px;
      width: auto;
      transition: height 0.3s ease-out;
    }
  }
</style>
