<template>
  <v-dialog
    class="image-widget"
    v-model="dialog"
    persistent
    fullscreen
    scrollable
    lazy
  >

    <slot slot="activator"></slot>

    <v-card>
      <v-card-text class="image-library-popup">

        <!-- <MediaManagement :includeManagementButtons="false" :singleSelection="true" @selectedImage="chooseImage($event)"/> -->
        <media-management-v2 :imageSelector="true" :isPopup="true" />
  
      </v-card-text>

      <v-card-actions class="fav-library-footer">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          outline
          flat
          @click="dialog = false;"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="$store.state.mediaLibrary.selectedImage.mediaLibraryID"
          color="accent"
          outline
          flat
          @click="selectImage()"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MediaManagementV2 from "@/components/Media/V2/MediaManagementV2"

export default {
  name: "MediaLibraryPopup",

  components: {
    MediaManagementV2
  },

  // watch: {
  //   '$store.state.mediaLibrary.selectedImage.mediaLibraryID': function() {
  //     if (this.$store.state.mediaLibrary.selectedImage.mediaLibraryID === this.result.mediaLibraryID) {
  //       this.isSelectedImage = true
  //     } else {
  //       this.isSelectedImage = false
  //     }
  //     console.log('State change', this.$store.state.mediaLibrary.selectedImage)
  //   }
  // },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/media`,
    dialog: false,
  }),

  mounted() {
    console.log("Media Library Mounted");
  },

  methods: {
    selectImage() {
      this.$emit("imageSelected", {
        mediaLibraryID: this.$store.state.mediaLibrary.selectedImage.mediaLibraryID
      })

      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .tabs {
    min-height: 100%;
  }

  .image-library-heading {
    align-items: flex-start;
    justify-content: space-between;
  }

  .image-library-footer {
    align-items: flex-end;
    padding: 20px;
  }

  .selected {
    border: 2px solid purple;
  }

  .image-library {
    margin: 0 0 30px;
    height: 100%;
    align-items: flex-start;
    overflow-y: visible;

    &__item {
      border: 2px solid transparent;
      border-radius: 4px;
    }

    &__start {
      width: 100%;
      color: #67247c;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
    }

    &__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px;
      height: 160px;
      width: 160px;
      color: #000;
      background-color: #ebebeb;
      background-size: contain;
      background-position: center;
    }

    &__title {
      margin: 10px 10px 30px;
      font-size: 16px;
      font-weight: normal;
    }

    &__top-bar {
      padding: 10px;
    }
  }

  .search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    background-color: #dfe3e9;

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 20px 20px 0;
      max-width: 400px;
      width: 100%;
    }
  }

  .filter-button {
    margin: 0 10px 0 0;
    padding: 5px 10px;
    font-size: 14px;
    background-color: #ccc;
    border-radius: 4px;

    &.current {
      color: #fff;
      background-color: #000;
    }
  }

  .fav-button {
    left: 137px;
    position: relative;
    bottom: 220px;
    color: green;
    background-color: #ccc;
    padding: 5px 10px;
    z-index:999;
  }

  .fav-library-footer {
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }

  .del-button {
    left: 77px;
    position: relative;
    bottom: 135px;
    padding: 5px 10px;
    color: red;
    background-color: #ccc;
    z-index:999;
    font-size: 8px;
  }

</style>
