<template>
    <section
        class="media-manager main-body"
        :class="{ 'is-popup': isPopup }"
    >
        <h1 class="media-manager__heading">Media Library</h1>

        <div class="media-manager__inner">
            <div class="media-manager__sidebar">
                <h3 class="media-manager__sidebar-heading">Quick links</h3>
                <ul class="media-manager__sidebar-list">
                    <!-- <li class="media-manager__sidebar-item"><v-icon class="media-manager__sidebar-icon">watch_later</v-icon> Recent</li> -->
                    <li
                        class="media-manager__sidebar-item"
                        @click="loadRoot()"
                    ><v-icon class="media-manager__sidebar-icon">folder</v-icon> Folders</li>
                    <li
                        class="media-manager__sidebar-item"
                        @click="openFavourites()"
                    ><v-icon class="media-manager__sidebar-icon">star</v-icon> Favourites</li>
                    <!-- <li class="media-manager__sidebar-item"><v-icon class="media-manager__sidebar-icon">delete</v-icon> Removed</li> -->
                </ul>

                <h3
                    class="media-manager__sidebar-heading"
                    @click="loadRoot()"
                >Folders</h3>
                <ul class="media-manager__sidebar-list">
                    <li class="media-manager__sidebar-item media-manager__sidebar-item--new">
                        <subdirectory-add @addFolder="createRootDirectory($event)">
                            <v-icon class="media-manager__sidebar-icon">create_new_folder</v-icon> <i>New Folder</i>
                        </subdirectory-add>
                    </li>

                    <li
                        class="media-manager__sidebar-item"
                        v-for="folder in directories"
                        :key="folder.mediaDirectoryID"
                        @click="loadDirectory('top', folder.mediaDirectoryID, folder.directoryName, folder.directories)"
                    >
                        <v-icon class="media-manager__sidebar-icon">folder</v-icon> {{ folder.directoryName }}
                    </li>
                </ul>
            </div>

            <div class="media-manager__main">
                <div class="media-manager__top">
                    <form
                        class="media-manager__search-container"
                        v-on:submit.prevent="search('SUBMIT SEARCH')"
                    >
                        <input
                            type="text"
                            placeholder="Search the library"
                            class="media-manager__search-input"
                            v-model.lazy="searchQuery"
                        />
                        <button
                            class="media-manager__search-submit"
                            @click="search('BUTTON SEARCH')"
                        >Search</button>
                        <!-- <button v-else class="media-manager__search-submit" @click="searchAgain()">Search Again</button> -->
                    </form>

                    <div v-if="uniquePanelOpen === 'uploader'">
                        <!-- Hide upload button -->
                    </div>
                    <button
                        v-else
                        class="media-manager__upload-button"
                        type="button"
                        @click="openUploader()"
                    >
                        <span class="media-manager__upload-button-main">Upload</span>
                        <span class="media-manager__upload-button-sub">Max File Size 2MB</span>
                    </button>
                </div>

                <div class="media-manager__body">
                    <div
                        v-if="loadingItems"
                        class="media-manager__inline-loader"
                    >
                        <inline-loader />
                    </div>

                    <div
                        v-else
                        class="media-manager__result-panel"
                    >
                        <div class="media-manager__result-header">
                            <template v-if="searchQuery">
                                <h2 class="media-manager__result-heading">Displaying results for '{{ searchQuery }}'
                                    {{ pageCount > 1 ? `: Page ${searchPageNumber}/${pageCount}` : '' }}
                                </h2>
                            </template>

                            <template v-else-if="uniquePanelOpen === 'favourites'">
                                <h2 class="media-manager__result-heading">Favourites</h2>
                            </template>

                            <template v-else-if="uniquePanelOpen === 'uploader'">
                                <!-- nothing here -->
                            </template>

                            <template v-else>
                                <template v-if="$store.state.mediaLibrary.activeTree.length > 1">
                                    <button
                                        class="media-manager__result-heading"
                                        @click="loadDirectory('up',
                                            parentDirectory.mediaDirectoryID,
                                            parentDirectory.directoryName,
                                            parentDirectory.subdirectories)"
                                    >... {{ parentDirectory.directoryName }}</button>
                                    <span> > </span>
                                </template>

                                <h2 class="media-manager__result-heading">{{ $store.state.mediaLibrary.directoryName }} <span>({{ directorySize
                                }} images)</span></h2>
                            </template>
                        </div>

                        <div class="media-manager__results">
                            <template v-if="uniquePanelOpen === 'search'">
                                <media-item
                                    v-for="result in searchResults"
                                    :key="result.mediaLibraryID"
                                    :result="result"
                                    :imageSelector="imageSelector"
                                    @openDetails="openDetails(result.mediaLibraryID)"
                                    @selectImage="selectImage(result.mediaLibraryID, result.key)"
                                    @removeImage="removeImage(result.mediaLibraryID, result.key)"
                                />

                                <p v-if="!searchResults.length">No results found</p>
                            </template>

                            <template v-else-if="uniquePanelOpen === 'favourites'">
                                <media-favourites :imageSelector="imageSelector" />
                            </template>

                            <template v-else-if="uniquePanelOpen === 'recent'">
                                Recents
                            </template>

                            <template v-else-if="uniquePanelOpen === 'uploader'">
                                <media-uploader
                                    :imageSelector="imageSelector"
                                    @openDirectory="loadRoot()"
                                />
                            </template>

                            <template v-else-if="$store.state.mediaLibrary.directoryID === 'root'">
                                <subdirectory-add @addFolder="createDirectory($event)">
                                    <subdirectory-new />
                                </subdirectory-add>

                                <template v-if="!loadingUpdatedSubdirectories">

                                    <subdirectory-item
                                        v-for="subdirectory in directories"
                                        :key="subdirectory.mediaDirectoryID"
                                        :subdirectory="subdirectory"
                                        @loadDirectory="loadDirectory('down', subdirectory.mediaDirectoryID, subdirectory.directoryName, subdirectory.directories)"
                                        @update="fetchDirectories()"
                                        @deleteDirectory="deleteDirectory(subdirectory.mediaDirectoryID, subdirectory.directoryName)"
                                    />

                                </template>

                                <media-item
                                    v-for="result in mediaItems"
                                    :key="result.mediaLibraryID"
                                    :result="result"
                                    :imageSelector="imageSelector"
                                    @openDetails="openDetails(result.mediaLibraryID)"
                                    @selectImage="selectImage(result.mediaLibraryID, result.key)"
                                    @removeImage="removeImage(result.mediaLibraryID, result.key)"
                                />
                            </template>

                            <template v-else>

                                <subdirectory-add @addFolder="createDirectory($event)">
                                    <subdirectory-new />
                                </subdirectory-add>

                                <template v-if="!loadingUpdatedSubdirectories && subdirectory">

                                    <subdirectory-item
                                        v-for="subdirectory in subdirectory.directories"
                                        :key="subdirectory.mediaDirectoryID"
                                        :subdirectory="subdirectory"
                                        @loadDirectory="loadDirectory('down', subdirectory.mediaDirectoryID, subdirectory.directoryName, subdirectory.directories)"
                                        @update="fetchDirectories()"
                                        @delete="deleteDirectory(subdirectory.mediaDirectoryID, subdirectory.directoryName)"
                                    />

                                </template>

                                <media-item
                                    v-for="result in mediaItems"
                                    :key="result.mediaLibraryID"
                                    :result="result"
                                    :imageSelector="imageSelector"
                                    @openDetails="openDetails(result.mediaLibraryID)"
                                    @selectImage="selectImage(result.mediaLibraryID, result.key)"
                                    @removeImage="removeImage(result.mediaLibraryID, result.key)"
                                />

                            </template>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="pagination">
            <v-pagination
                v-if="!isSearch"
                v-model="pageNumber"
                :length="pageCount"
                :total-visible="7"
                circle
                color="accent"
            >
            </v-pagination>

            <v-pagination
                v-if="isSearch"
                v-model="searchPageNumber"
                :length="pageCount"
                :total-visible="7"
                circle
                color="accent"
            >
            </v-pagination>
        </div>

        <transition
            name="multi-docked"
            v-if="!imageSelector"
        >
            <media-mover
                v-if="$store.state.mediaLibrary.multiSelectedImages.length"
                @update="fetchItems($store.state.mediaLibrary.directoryID)"
            />
        </transition>

        <transition
            name="clipboard-docked"
            v-if="imageSelector"
        >
            <clipboard-preview v-if="$store.state.mediaLibrary.selectedImage.mediaLibraryID" />
        </transition>
    </section>
</template>

<script>
import axios from "axios"
import ClipboardPreview from "@/components/Media/V2/ClipboardPreview.vue"
import InlineLoader from "@/components/Library/InlineLoader"
import MediaFavourites from "@/components/Media/V2/MediaFavourites.vue"
import MediaItem from "@/components/Media/V2/MediaItem.vue"
import MediaMover from "@/components/Media/V2/MediaMover.vue"
import MediaUploader from "@/components/Media/V2/MediaUploader.vue"
import SubdirectoryAdd from "@/components/Media/V2/SubdirectoryAdd.vue"
import SubdirectoryItem from "@/components/Media/V2/SubdirectoryItem.vue"
import SubdirectoryNew from "@/components/Media/V2/SubdirectoryNew.vue"

export default {
    name: "MediaManagement",

    components: {
        ClipboardPreview,
        InlineLoader,
        MediaFavourites,
        MediaItem,
        MediaMover,
        MediaUploader,
        SubdirectoryAdd,
        SubdirectoryItem,
        SubdirectoryNew
    },

    props: {
        imageSelector: {
            type: Boolean,
            default: false
        },
        isPopup: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        pageNumber(value, oldValue) {
            console.log("Page number changed");
            if (oldValue) {
                this.fetchItems(this.directoryID)
            }
        },
        searchPageNumber(value, oldValue) {
            console.log("Search page number changed")
            if (oldValue) {
                this.search()
            }
        },
        isSearch() {
            console.log("isSearch", this.isSearch)
            if (this.isSearch) {
                this.searchPageNumber = 1
                console.log("1")
            } else {
                this.pageNumber = 1
                console.log("2")
            }
            console.log("isSearch", this.isSearch)
        }
    },

    data: () => ({
        api: `${process.env.VUE_APP_BASEURL}/media`,
        directories: [],
        directoryID: "",
        mediaItems: [],
        directorySize: 0,
        pageCount: 0,
        searchPageNumber: 1,
        isSearch: null,
        loadingItems: false,
        imageURL: process.env.VUE_APP_IMAGEURL,
        path: null,
        loadingUpdatedSubdirectories: false,
        subdirectory: {},
        parentDirectory: {},
        searchQuery: "",
        searchResults: [],
        uniquePanelOpen: "", // recent || search || favourites || uploader || details
        pageSize: 30,
        pageNumber: 1
    }),

    mounted() {
        this.fetchDirectories(true)
    },

    methods: {
        directoryLocator() {
            // Function of shame
            let index1Store
            let index2Store
            let index3Store
            let index4Store
            let index5Store
            let index6Store

            let whereAmI
            let whereAmIParent

            // Level 1
            let tempIndex = 0
            if (this.$store.state.mediaLibrary.activeTree.length > 0) {
                for (const directory of this.directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[0]) {
                        index1Store = tempIndex
                        break;
                    }
                    tempIndex++
                }


                whereAmI = this.directories[index1Store]
                whereAmIParent = null
            }

            // Level 2
            if (this.$store.state.mediaLibrary.activeTree.length > 1 && this.directories[index1Store].directories.length) {
                console.log("Has child directories")


                let tempIndex = 0
                for (const directory of this.directories[index1Store].directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[1]) {
                        index2Store = tempIndex
                        break;
                    }
                    tempIndex++
                }

                whereAmI = this.directories[index1Store].directories[index2Store]
                whereAmIParent = this.directories[index1Store]
            }

            // Level 3
            if (this.$store.state.mediaLibrary.activeTree.length > 2 && this.directories[index1Store].directories[index2Store].directories.length) {
                console.log("Has child directories")


                let tempIndex = 0
                for (const directory of this.directories[index1Store].directories[index2Store].directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[2]) {
                        index3Store = tempIndex
                        break;
                    }
                    tempIndex++
                }

                whereAmI = this.directories[index1Store].directories[index2Store].directories[index3Store]
                whereAmIParent = this.directories[index1Store].directories[index2Store]
            }

            // Level 4
            if (this.$store.state.mediaLibrary.activeTree.length > 3 && this.directories[index1Store].directories[index2Store].directories[index3Store].directories.length) {
                console.log("Has child directories")


                let tempIndex = 0
                for (const directory of this.directories[index1Store].directories[index2Store].directories[index3Store].directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[3]) {
                        index4Store = tempIndex
                        break;
                    }
                    tempIndex++
                }

                whereAmI = this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store]
                whereAmIParent = this.directories[index1Store].directories[index2Store].directories[index3Store]
            }

            // Level 5
            if (this.$store.state.mediaLibrary.activeTree.length > 4 && this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories.length) {
                console.log("Has child directories")


                let tempIndex = 0
                for (const directory of this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[4]) {
                        index5Store = tempIndex
                        break;
                    }
                    tempIndex++
                }

                whereAmI = this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories[index5Store]
                whereAmIParent = this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store]
            }

            // Level 6
            if (this.$store.state.mediaLibrary.activeTree.length > 5 && this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories[index5Store].directories.length) {
                console.log("Has child directories")


                let tempIndex = 0
                for (const directory of this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories[index5Store].directories) {
                    if (directory.mediaDirectoryID === this.$store.state.mediaLibrary.activeTree[5]) {
                        index6Store = tempIndex
                        break;
                    }
                    tempIndex++
                }

                whereAmI = this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories[index5Store].directories[index6Store]
                whereAmIParent = this.directories[index1Store].directories[index2Store].directories[index3Store].directories[index4Store].directories[index5Store]
            }

            console.log("whereAmI", whereAmI)
            console.log("whereAmIParent", whereAmIParent)
            this.subdirectory = whereAmI
            this.parentDirectory = whereAmIParent
        },

        fetchDirectories(initialLoad) {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/directories/`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        this.directories = response.data.directories

                        if (initialLoad) {
                            this.loadRoot()
                        }

                        this.directoryLocator()
                    })
                    .catch(e => {
                        console.error("Problem retrieving directories", e);
                        this.$store.commit("completeLoading");
                    });
            })
        },

        createRootDirectory(folderName) {
            console.log("Add root folder", folderName)

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.api}/directory/add`,
                    headers: { "Authorization": jwt },
                    data: {
                        directoryName: folderName
                    }
                })
                    .then(response => {
                        console.log("Folder added:", response);
                        this.fetchDirectories()
                    })
                    .catch(e => {
                        console.error("Problem adding folder", e);
                    });
            })
        },

        createDirectory(folderName) {
            console.log("Add folder", folderName)

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.api}/directory/add`,
                    headers: { "Authorization": jwt },
                    data: {
                        directoryName: folderName,
                        parentDirectoryID: this.$store.state.mediaLibrary.directoryID
                    }
                })
                    .then(response => {
                        console.log("Folder added:", response);
                        this.fetchDirectories()
                    })
                    .catch(e => {
                        console.error("Problem adding folder", e);
                    });
            })
        },

        deleteDirectory(directoryID, directoryName) {
            if (confirm(`Are you sure you want to delete "${directoryName}"? This cannot be undone (note: All media items will still be accessible)`)) {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${this.api}/directory/${directoryID}`,
                        headers: { "Authorization": jwt }
                    })
                        .then(response => {
                            console.log("Folder deleted:", response);
                            this.fetchDirectories()
                        })
                        .catch(e => {
                            console.error("Problem deleting directory", e);
                        });
                })
            }
        },

        loadRoot() {
            this.$store.commit("storeActiveTree", [])

            this.$store.commit("storeDirectoryDetails", {
                directoryID: "root",
                directoryName: "Root",
                subdirectories: this.directories
            })

            this.loadingItems = true
            this.mediaItems = []
            this.searchResults = []
            this.searchQuery = ""
            this.$store.commit("multiSelectImageClearAll")
            this.uniquePanelOpen = ""

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/directory/root?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        this.mediaItems = response.data.mediaItems
                        this.directorySize = response.data.totalCount
                        this.pageCount = Math.ceil(response.data.totalCount / this.pageSize);
                        this.loadingItems = false
                    })
                    .catch(e => {
                        this.loadingItems = false
                        console.error("Problem retrieving directory", e);
                    });
            })
        },

        loadDirectory(direction, directoryID, directoryName, subdirectories) {
            this.loadingItems = true
            this.mediaItems = []
            this.searchResults = []
            this.searchQuery = ""
            this.$store.commit("multiSelectImageClearAll")
            this.uniquePanelOpen = ""

            console.log("direction", direction)
            console.log("directoryID", directoryID)
            console.log("directoryName", directoryName)
            console.log("subdirectories", subdirectories)

            if (directoryID) {

                if (direction === "up") {
                    const activeTree = this.$store.state.mediaLibrary.activeTree

                    activeTree.pop()

                    this.$store.commit("storeActiveTree", activeTree)
                } else if (direction === "down") {
                    console.log("")
                    const activeTree = this.$store.state.mediaLibrary.activeTree

                    activeTree.push(directoryID)

                    console.log("activeTree", activeTree)

                    this.$store.commit("storeActiveTree", activeTree)
                } else {
                    // else top
                    console.log("top", [directoryID])
                    this.$store.commit("storeActiveTree", [directoryID])
                }

                this.$store.commit("storeDirectoryDetails", {
                    directoryID,
                    directoryName,
                    subdirectories
                })

                this.directoryLocator()

                this.fetchItems(directoryID, "Directory Change")
            }
        },

        fetchItems(directoryID, string) {
            console.log("ID", directoryID)
            if (string) {
                this.pageNumber = 1
            }
            // console.log('FETCH searchPageNumber', this.searchPageNumber, 'FETCH pageNumber', this.pageNumber)
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/directory/${directoryID}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        this.mediaItems = response.data.mediaItems
                        this.directorySize = response.data.totalCount
                        this.pageCount = Math.ceil(response.data.totalCount / this.pageSize);
                        this.loadingItems = false
                        this.directoryID = directoryID
                        this.isSearch = false

                    })
                    .catch(e => {
                        this.loadingItems = false
                        console.error("Problem retrieving directory", e);
                    });
            })
        },

        search(string) {
            // console.log('Search for:', this.searchQuery)
            // console.log('SEARCH searchPageNumber', this.searchPageNumber, 'SEARCH pageNumber', this.pageNumber)

            if (string) {
                this.searchPageNumber = 1
            }

            this.isSearch = true
            this.loadingItems = true
            this.searchResults = []
            this.mediaItems = []
            this.uniquePanelOpen = "search"
            this.$store.commit("multiSelectImageClearAll")
            this.$store.commit("storeDirectoryDetails", {
                directoryID: "root",
                directoryName: "Root",
                subdirectories: []
            })

            this.$store.commit("startLoading");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    // .get(`${this.api}/?searchTerm=${this.searchQuery}&pageNumber=${this.searchPageNumber}&pageSize=${this.pageSize}`, { 'headers': { 'Authorization': jwt }})
                    .get(`${this.api}/?pageNumber=${this.searchPageNumber}&pageSize=${this.pageSize}&searchTerm=${this.searchQuery}`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        console.log("fetch library response: ", response);

                        this.searchResults = response.data.body
                        this.loadingItems = false
                        this.pageCount = Math.ceil(response.data.totalDataCount / this.pageSize);
                        this.searchPageNumber = this.searchPageNumber

                        this.$store.commit("completeLoading");
                    })
                    .catch(e => {
                        console.error("Problem retrieving page: ", e);
                        this.$store.commit("completeLoading");
                        this.loadingItems = false
                        this.errorMessage = "Problem retrieving page"
                    });
            })
        },

        openFavourites() {
            this.mediaItems = []
            this.searchResults = []
            this.searchQuery = ""
            this.$store.commit("multiSelectImageClearAll")
            this.uniquePanelOpen = "favourites"
        },

        openUploader() {
            this.mediaItems = []
            this.searchResults = []
            this.searchQuery = ""
            this.$store.commit("multiSelectImageClearAll")
            this.uniquePanelOpen = "uploader"

            this.$store.commit("removeImage", {
                mediaLibraryID: "",
                imageKey: ""
            });
        },

        openDetails(mediaLibraryID) {
            console.log("openDetails", mediaLibraryID)
            this.$store.commit("editImage", mediaLibraryID);
        },

        selectImage(mediaLibraryID, imageKey) {
            console.log("selectImage: ", mediaLibraryID)

            if (this.imageSelector) {
                this.$store.commit("selectImage", {
                    mediaLibraryID,
                    imageKey
                });
            } else {
                alert("Not image selector")
            }
        },

        removeImage(mediaLibraryID, imageKey) {
            if (this.imageSelector) {
                this.$store.commit("removeImage", {
                    mediaLibraryID,
                    imageKey
                });
            } else {
                alert("Not image selector")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.media-manager {
    &.is-popup {
        padding: 0;
        border-top: 0;
    }

    &__heading {
        margin: 0 0 1em;
        color: #67247c;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.24px;
    }

    &__inner {
        // height: calc(100vh - 300px);
        // overflow-y: scroll;

        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0 24px 0 0;
        border-radius: 4px;
        border: solid 1px #dfe3e9;
        // box-shadow: 0 10px 16px 24px rgba(0, 0, 0, 0.08);
        background-color: #fff;
    }

    &__sidebar {
        height: 100%;
        max-width: 160px;
        min-width: 160px;
        padding: 22px 8px;
        background-color: #fafafa;
    }

    &__sidebar-heading {
        padding: 0;
        margin: 0 0 8px;
        color: #24002f;
        font-size: 16px;
        font-weight: 400;
    }

    &__sidebar-list {
        margin: 0 0 25px;
        padding: 0;
    }

    &__sidebar-item {
        margin: 0 0 8px;
        padding: 0;
        color: #67247c;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &--new {
            font-style: italic;
            font-weight: 400;
        }
    }

    &__sidebar-icon {
        position: relative;
        top: -1px;
        margin: 0 4px 0 0;
        color: #67247c;
        font-size: 17px;
    }

    &__main {
        width: 100%;
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__body {
        width: 100%;
    }

    &__inline-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 64px auto;
        width: 100%;
        text-align: center;
        color: #67247c;
    }

    &__result-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    &__result-heading {
        margin: 16px;
        color: #24002f;
        font-size: 16px;
        font-weight: 500;

        span {
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
        }
    }

    &__results {
        position: relative; //maybe
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__search-container {
        position: relative;
        margin: 22px 32px 0 16px;
        max-width: 425px;
    }

    &__search-input {
        padding: 11px 140px 12px 17px;
        width: 100%;
        border: 1px solid #dfe3e9;
        border-radius: 4px;
        background-color: #fafafa;
    }

    &__search-submit {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        width: 120px;
        color: #67247c;
        font-weight: 500;
        background-color: #fff;
        border-radius: 0 4px 4px 0;
        border-left: 1px solid #dfe3e9;
    }

    &__upload-button {
        padding: 0;
        margin: 0;
        text-align: right;
        background: transparent;
        border: 0;
        outline: none;
    }

    &__upload-button-main {
        display: block;
        margin: 0;
        color: #67247c;
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-weight: 500;
    }

    &__upload-button-sub {
        display: block;
        margin: 0;
        color: #baadbe;
        font-size: 10px;
        font-style: italic;
        font-weight: 400;
    }
}

.clipboard-docked-enter-active {
    animation: clipboard-docked-in .5s;
}

.clipboard-docked-leave-active {
    animation: clipboard-docked-out .5s;
}

@keyframes clipboard-docked-in {
    0% {
        opacity: 0;
        transform: translateY(100%) translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }
}

@keyframes clipboard-docked-out {
    0% {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }

    100% {
        opacity: 0;
        transform: translateY(100%) translateX(-50%);
    }
}

.multi-docked-enter-active {
    animation: multi-docked-in .5s;
}

.multi-docked-leave-active {
    animation: multi-docked-out .5s;
}

@keyframes multi-docked-in {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes multi-docked-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}
</style>
