<template>
    <v-flex
        class="image-editor"
        :class="{ 'small-spacing': hasSmallSpacing }"
    >
        <h3 class="label">{{ title }}</h3>
        <div
            v-if="imageSrc"
            class="image-editor__image-wrap"
        >
            <img
                class="image-editor__image"
                :src="`${imageSrc}`"
            />

            <div class="image-editor__actions">

                <v-btn
                    v-if="!preventDeletion"
                    class="image-editor__image-delete"
                    type="button"
                    color="error"
                    depressed
                    small
                    @click="removeImage()"
                >
                    <v-icon small>remove</v-icon>
                    Remove
                </v-btn>

                <media-library-popup @imageSelected="editImage($event)">
                    <v-btn
                        class="image-editor__image-library"
                        color="accent"
                        type="button"
                        depressed
                    >
                        <v-icon small>photo_library</v-icon>
                        Open Media library
                    </v-btn>
                </media-library-popup>
            </div>
        </div>

        <div
            v-else
            class="image-editor__image-wrap"
        >
            <div class="image-editor__image image-editor__image--empty">
                Image not selected
            </div>

            <div class="image-editor__actions">

                <media-library-popup @imageSelected="editImage($event)">
                    <v-btn
                        class="image-editor__image-library"
                        color="accent"
                        type="button"
                        depressed
                    >
                        <v-icon small>photo_library</v-icon>
                        Open Media library
                    </v-btn>
                </media-library-popup>

            </div>
        </div>

        <div
            v-if="desc"
            class="desc"
            v-html="desc"
        ></div>

    </v-flex>
</template>

<script>
import axios from "axios"
import FileUploadModal from "@/components/Library/FileUploadModal"
import MediaLibraryPopup from "@/components/Library/MediaLibraryPopup"

export default {
    name: "ImageUploader",

    components: {
        FileUploadModal,
        MediaLibraryPopup
    },

    props: {
        title: {
            type: String,
            required: true
        },
        desc: {
            type: String,
            required: false
        },
        widthOptionEnabled: {
            type: Boolean,
            default: true
        },
        mediaLibraryID: {
            type: String,
            required: false
        },
        preventDeletion: {
            type: Boolean,
            default: false,
        },
        hasSmallSpacing: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        imageURL: process.env.VUE_APP_IMAGEURL,
        imageSrc: null,
        imageKey: "",
        updatedMediaLibraryID: null,
    }),

    mounted() {
        this.mediaLibrary()
    },

    watch: {
        mediaLibraryID: function () {
            this.mediaLibrary()
        }
    },

    methods: {
        updateData() {
            this.$emit("update", {
                mediaLibraryID: this.updatedMediaLibraryID,
                imageKey: this.imageKey
            })
        },

        editImage(payload) {
            console.log("Edit image: ", payload)
            this.updatedMediaLibraryID = payload.mediaLibraryID

            this.$store.commit("removeImage", {
                mediaLibraryID: "",
                imageKey: ""
            });

            this.fetchImage()
        },

        removeImage() {
            this.imageSrc = ""
            this.updatedMediaLibraryID = ""
            this.imageKey = ""

            this.updateData()
        },

        mediaLibrary() {
            if (this.mediaLibraryID) {
                this.updatedMediaLibraryID = JSON.parse(JSON.stringify(this.mediaLibraryID))

                this.fetchImage();
            }
        },

        fetchImage() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/media/${this.updatedMediaLibraryID}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("Fetch image response:", response);

                        if (response.data.body.location) {
                            this.imageSrc = response.data.body.location;
                        } else if (response.data.body.fileType === "image/gif") {
                            this.imageSrc = `${this.imageURL}/${response.data.body.key}`;
                            this.imageKey = response.data.body.key
                        } else {
                            this.imageSrc = `${this.imageURL}/fit-in/300x300/filters:quality(20)/${response.data.body.key}`;
                            this.imageKey = response.data.body.key
                        }

                        this.updateData()
                    })
                    .catch(e => {
                        console.error("Problem fetching image", e);
                    });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.desc {
    padding: 10px 10px 0;
    margin: 0;
    font-size: 13px;
    background-color: #f4f6d2;
    border: 1px solid #d6d8b0;
}

.image-editor {
    padding: 20px;
    margin: 10px 0;
    background-color: #f8f8f8;
    border: 1px solid #ebebeb;

    &__image-wrap {
        flex-wrap: wrap;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__image-delete {
        margin: 20px 0;
    }

    &__image-library {
        margin: 20px 0;
    }
}

.small-spacing {
    padding: 10px;

    .image-editor {

        &__image-delete,
        &__image-library {
            margin: 0;
        }

        &__image-delete {
            margin-bottom: 10px;
        }

        &__image-wrap {
            margin: 0;
        }

        &__image {
            margin-right: 10px;
            margin-bottom: 0;
        }
    }

    .desc {
        margin-top: 10px;
    }
}
</style>

