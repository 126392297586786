<template>
  <article class="subfolder" :key="subdirectory.mediaDirectoryID">
    <button class="subfolder__quick-edit-toggle" type="button" @click="quickEdit = !quickEdit">
      <v-icon>more_horiz</v-icon>
    </button>

    <div v-if="quickEdit" class="subfolder__quick-edit-panel">
      <subdirectory-rename :mediaDirectoryID="subdirectory.mediaDirectoryID" :directoryName="subdirectory.directoryName" @update="$emit('update')">
        <button class="subfolder__quick-edit-button" type="button">
          <v-icon class="subfolder__quick-edit-icon">edit</v-icon> Edit name</button>
      </subdirectory-rename>
      <button class="subfolder__quick-edit-button" type="button" @click="$emit('deleteDirectory')">
        <v-icon class="subfolder__quick-edit-icon">delete</v-icon> Delete folder</button>
    </div>
    
    <v-icon @click="$emit('loadDirectory')" class="subfolder__icon">folder</v-icon>
    <p @click="$emit('loadDirectory')" class="subfolder__name">{{ subdirectory.directoryName }}</p>

  </article>
</template>

<script>
import SubdirectoryRename from "@/components/Media/V2/SubdirectoryRename.vue"

export default {
  name: "SubdirectoryItem",

  components: {
    SubdirectoryRename
  },

  props: {
    subdirectory: {
      type: Object,
      required: true
    }
  },

  data:() => ({
    quickEdit: false,
    editDirectoryName: false
  }),

  methods: {
    openNameEdit() {
      console.log("openNameEdit")
      this.editDirectoryName = true
    }
  }
}
</script>

<style scoped lang="scss">
  .subfolder {
    position: relative;
    margin: 0 0 24px 16px;
    width: 160px;

    &__icon {
      width: 160px;
      height: 120px;
      padding: 50px;
      margin: 0 0 5px;
      color: #67247c;
      font-size: 80px;
      text-align: center;
      border-radius: 4px;
      background-color: #fafafa;
    }

    &__name {
      margin: 4px auto 0 0;
      padding-right: 4px;
      color: #24002f;
      font-size: 12px;
      font-weight: 400;
    }

    &__quick-edit-toggle {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;
      margin: 0;
      color: #000;
      font-size: 14px;
      background: transparent;
      border: 0;
      z-index: 2;
      outline: none;
    }

    &__quick-edit-panel {
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 30px 0 4px 7px;
      margin: 0;
      width: 108px;
      color: #516173;
      font-size: 12px;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background-color: #ffffff;
      z-index: 1;
    }

    &__quick-edit-button {
      margin: 0 0 6px;
      padding: 0;
      color: #516173;
      background: transparent;
      border: 0;
    }

    &__quick-edit-icon {
      color: #516173;
      font-size: 14px;
    }
  }
</style>
