<template>
  <article v-if="result" class="media-item" :class="{'media-item--selected' : isSelectedImage}">
    <template v-if="imageSelector">
      <button v-if="isSelectedImage" @click="removeImage()" class="media-item__quick-select" type="button">
        <v-icon class="media-item__quick-select-icon media-item__quick-select-icon--selected">radio_button_checked</v-icon>
      </button>

      <button v-else class="media-item__quick-select" @click="selectImage()" type="button">
        <v-icon class="media-item__quick-select-icon">radio_button_unchecked</v-icon>
      </button>
    </template>

    <button class="media-item__quick-edit-toggle" type="button" @click="quickEdit = !quickEdit">
      <v-icon>more_horiz</v-icon>
    </button>

    <div v-if="result.fileType === 'image/jpeg' || result.fileType === 'image/png' || result.fileType === 'image/gif'" class="media-item__image" :style="fetchMediaImage(result.fileType, result.key)">
    </div>
    <div v-else class="media-item__image unsupported">
    </div>

    <div class="media-item__meta" v-if="!updating">
      <div class="media-item__actions">
        <button v-if="checkDuplicatesInFavourites(result.mediaLibraryID)" @click="deleteFavourite(result.mediaLibraryID)" type="button" class="media-item__action-button">
          <v-icon class="media-item__action-fav">star</v-icon>
        </button>

        <button v-else @click="addFavourite(result.mediaLibraryID)" type="button" class="media-item__action-button">
          <v-icon class="media-item__action-not-fav">star_outline</v-icon>
        </button>
      </div>
  
      <h4 class="media-item__name">{{ result.name }}</h4>
    </div>

    <div v-if="quickEdit" class="media-item__quick-edit-panel">
      <button @click="openDetails()" class="media-item__quick-edit-button" type="button">
        <v-icon class="media-item__quick-edit-icon">edit</v-icon> View details
      </button>

      <a class="media-item__quick-edit-button" target="_blank" :href="`${imageURL}/${result.key}`" @click.prevent="download()"><v-icon class="media-item__quick-edit-icon">download</v-icon> Download</a>
    </div>
  </article>
</template>

<script>
import axios from "axios"

export default {
  name: "FavouriteItem",

  props: {
    mediaLibraryID: {
      type: String,
      required: true
    },
    imageSelector: {
      type: Boolean,
      default: false
    }
  },

  data:() => ({
    imageURL: process.env.VUE_APP_IMAGEURL,
    updating: false,
    result: null,
    quickEdit: false,
    isSelectedImage: false
  }),

  watch: {
    "$store.state.mediaLibrary.selectedImage.mediaLibraryID": function() {
      if (this.$store.state.mediaLibrary.selectedImage.mediaLibraryID === this.result.mediaLibraryID) {
        this.isSelectedImage = true
      } else {
        this.isSelectedImage = false
      }
      // console.log('State change', this.$store.state.mediaLibrary.selectedImage)
    }
  },

  mounted() {
    console.log("Load: ", this.mediaLibraryID)
    this.fetchImage()
  },

  methods: {
    selectImage() {
      if (this.imageSelector) {
        this.$store.commit("selectImage", {
          mediaLibraryID: this.result.mediaLibraryID,
          imageKey: this.result.key
        });
      } else {
        alert("Not image selector")
      }
    },

    removeImage() {
      if (this.imageSelector) {
        this.$store.commit("removeImage", {
          mediaLibraryID: this.result.mediaLibraryID,
          imageKey: this.result.key
        });
      } else {
        alert("Not image selector")
      }
    },

    openDetails() {
      console.log("openDetails", this.result.mediaLibraryID)
      this.$store.commit("editImage", this.result.mediaLibraryID);
    },

    fetchImage() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/media/${this.mediaLibraryID}`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch image response:", response);

          this.result = response.data.body
        })
        .catch(e => {
          console.error("Problem fetching image", e);
        });
      })
    },

    fetchMediaImage(fileType, key) {
      if (fileType === "image/jpeg" || fileType === "image/png") {
        return `background: url('${this.imageURL}/fit-in/150x150/filters:quality(20)/${key}');background-repeat: no-repeat;background-size: contain; background-position: center;`
      } else {
        return ""
      }
    },

    addFavourite(mediaLibraryItem) {
      console.log("addFavourite")
      if (!localStorage.gc_mediaFavourites) {
        localStorage.gc_mediaFavourites = JSON.stringify([])
      }

      console.log("localStorage.gc_mediaFavourites", localStorage.gc_mediaFavourites)

      if (this.checkDuplicatesInFavourites(mediaLibraryItem) === false) {

        this.updating = true

        const updateLocalStorage = JSON.parse(localStorage.gc_mediaFavourites)
        updateLocalStorage.push(mediaLibraryItem)

        console.log("updateLocalStorage", updateLocalStorage)

        localStorage.setItem("gc_mediaFavourites", JSON.stringify(updateLocalStorage));

        this.updating = false

        // this.checkDuplicatesInFavourites(mediaLibraryItem)
      }
    },

    deleteFavourite(mediaLibraryItem) {
      if (this.checkDuplicatesInFavourites(mediaLibraryItem)) {

        this.updating = true

        const updateLocalStorage = JSON.parse(localStorage.gc_mediaFavourites)
        const indexOfItem = updateLocalStorage.indexOf(mediaLibraryItem)

        updateLocalStorage.splice(indexOfItem, 1)

        localStorage.setItem("gc_mediaFavourites", JSON.stringify(updateLocalStorage));

        this.updating = false

        // this.checkDuplicatesInFavourites(mediaLibraryItem)
      }
    },

    checkDuplicatesInFavourites(mediaLibraryItem) {
      // console.log('checkDuplicatesInFavourites', mediaLibraryItem)
      let isDup = false
      if (localStorage.gc_mediaFavourites) {
        if (localStorage.gc_mediaFavourites.includes(mediaLibraryItem)) {
          console.log("Is matched")
          isDup = true
          this.favourited = true
        }
      }
      this.favourited = false
      return isDup
    },

    download() {
      axios({
        url: `${this.imageURL}/${this.result.key}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.result.key);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
  }
}
</script>

<style scoped lang="scss">
  .media-item {
    position: relative;
    margin: 0 0 24px 16px;
    width: 160px;
    border-radius: 4px;
    transition: box-shadow 0.2s ease-out;

    &--selected {
      box-shadow: 0 0 3px 2px #67247c;
    }

    &__meta {
      position: relative;
    }

    &__image {
      width: 160px;
      height: 120px;
      margin: 0 0 5px;
      border-radius: 4px;
      border: 1px dashed #ebebeb;
      background: #ebebeb url('../../../assets/image-missing.png');
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;

      &.unsupported {
        padding: 50px;
        color: #3c3c3c;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &__name {
      margin: 4px auto 0 0;
      padding: 0 4px 2px;
      color: #24002f;
      font-size: 12px;
      font-weight: 400;
      word-break: break-word;
    }

    &__link {
      text-decoration: none;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__actions {
      float: right;
      padding: 0 4px 0 0;
    }

    &__action-button {
      float: right;
      padding: 0;
      margin: 0;
      background: transparent;
      border: 0;
      outline: none;
    }

    &__action {
      margin: 2px 0 0 5px;
      font-size: 20px;
      outline: none;
    }

    &__action-fav {
      color: #67247c;
      margin: 1px 0 0 5px;
      font-size: 18px;
      outline: none;
    }

    &__action-not-fav {
      margin: 1px 0 0 5px;
      font-size: 18px;
      outline: none;
    }

    &__quick-edit-toggle {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;
      margin: 0;
      color: #000;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.3);
      border: 0;
      border-radius: 0 0 0 4px;
      z-index: 2;
      outline: none;
    }

    &__quick-edit-panel {
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 30px 0 4px 7px;
      margin: 0;
      width: 108px;
      color: #516173;
      font-size: 12px;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background-color: #ffffff;
      z-index: 1;
    }

    &__quick-edit-button {
      display: block;
      margin: 0 0 6px;
      padding: 0;
      color: #516173;
      background: transparent;
      border: 0;
      text-decoration: none;
    }

    &__quick-edit-icon {
      color: #516173;
      font-size: 14px;
    }

    &__quick-select {
      position: absolute;
      top: 3px;
      left: 3px;
      padding: 1px 1px 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      outline: none;
    }

    &__quick-select-icon {
      color: #fff;

      &--selected {
        color: #fff;
      }
    }
  }

</style>
