<template>
  <section class="favourites">
    <favourite-item v-for="(favourite, index) in favourites" :key="`fav-${index}`" :mediaLibraryID="favourite" :imageSelector="imageSelector" />
  </section>
</template>

<script>
import FavouriteItem from "@/components/Media/V2/FavouriteItem.vue"

export default {
  name: "MediaFavourites",

  components: {
    FavouriteItem
  },

  props: {
    imageSelector: {
      type: Boolean,
      default: false
    }
  },

  data:() => ({
    favourites: [],
  }),

  mounted() {
    this.getFavourites()
  },

  methods: {
    getFavourites() {
      this.favourites = JSON.parse(localStorage.gc_mediaFavourites)
    },
  }
}
</script>

<style scoped lang="scss">
  .favourites {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
</style>
