<template>
  <v-dialog
    class="image-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

    <v-form enctype="multipart/form-data" ref="imageWidgetForm" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Image
        </v-card-title>

        <v-card-text>

          <div class="uploader">
            <h4 class="uploader__heading">Add Image</h4>

            <div class="uploader__ctas">

              <v-btn
                color="accent"
                type="button"
                depressed>
                <label for="file-upload" class="uploader__upload-button"><v-icon small>add</v-icon> Choose image</label>
              </v-btn>

              <input v-if="imageUploadOnly" class="uploader__upload-input" id="file-upload" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*,.pdf"/>

              <input v-else class="uploader__upload-input" id="file-upload" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
            </div>
          </div>

          <section class="single-image" v-if="previewImage">

            <img class="single-image__preview" :src="previewImage" />

            <section class="edit">
              <label class="label" for="title">Title (required)</label>
              <v-text-field
                id="title"
                v-model="imageData.name"
                solo
                flat
                required
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>

              <label class="label" for="caption">Caption (required)</label>
              <v-text-field
                id="caption"
                v-model="imageData.description"
                solo
                required
                :rules="[rules.required]"
                flat
                validate-on-blur
              ></v-text-field>

              <label class="label" for="tags">Tags</label>

              <div class="tag-manager">
                <v-text-field
                  class="tag-manager__input"
                  id="tags"
                  v-model="tagString"
                  solo
                  flat
                  :hide-details="true"
                  @keyup.enter.native="tagged(tagString)"
                  placeholder="Hit enter to add tags"
                ></v-text-field>
                <template v-if="imageData.tags" v-for="(tag, index) in imageData.tags">
                  <v-chip class="tag-manager__tag" color="accent" text-color="white" :v-model="tag.id">{{tag.name}}<span class="tag-manager__close" @click="removeTag(index)">X</span></v-chip>
                </template>
              </div>

              <FolderSelector @moveItems="selectFolder($event)">
                <span class="ma-2 select-folder-button v-btn v-btn--flat v-btn--outline v-btn--depressed theme--light accent--text" color="purple darken-4" dark>Add to folder</span>
              </FolderSelector>

            </section>
          </section>

        </v-card-text>

        <v-card-actions v-if="previewImage">
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            @click="validate();"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios"
import FolderSelector from "@/components/Media/FolderSelector.vue"

export default {
  name: "FileUploadModal",

  components: {
    FolderSelector
  },

  props: {
    imageUploadOnly: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    previewImage: null,
    tagString: "",
    imageData: {
      mediaDirectoryID: "",
      image: "",
      name: "",
      description: "",
      tags: [],
      width: null
    },
    rules: {
      required: value => !!value || "Required."
    }
  }),

  mounted() {
    console.log("image-widget Mounted");
  },

  watch: {
    tagString: function(val) {
      if (val.includes(",")) {
        const trimmedVal = val.slice(0, -1);
        this.imageData.tags.push(trimmedVal);
        this.tagString = "";
      }
    }
  },

  methods: {
    selectFolder(mediaDirectoryID) {
      console.log("mediaDirectoryID selected", mediaDirectoryID)
      this.imageData.mediaDirectoryID = mediaDirectoryID
    },

    convertImage($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewImage = reader.result;

        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        console.log("base64DataOnly", base64DataOnly)

        this.imageData = {
          image: base64DataOnly,
          name: "",
          description: "",
          tags: []
        }
      });

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    uploadImage() {
      console.log("Upload image: ", this.imageData);
      this.imageData.width = this.imageWidth
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/upload`,
          headers: { "Authorization": jwt },
          data: this.imageData
        })
        .then(response => {
          console.log("Saved image: ", response);

          this.$emit("imageUploaded", {
            mediaLibraryID: response.data.body.mediaLibraryID,
            width: this.imageData.width
          });
        })
        .catch(e => {
          console.error("Problem saving image: ", e);
        });
      })
    },

    tagged(item){
      console.log("Adding tag:", item);
      const tag = {
        id: item.replace(/\s/g,""),
        name: item
      }
      this.imageData.tags.push(tag);
      this.tagString = "";
      console.log(this.imageData.tags);
    },
    removeTag(index) {
      this.imageData.tags.splice(index, 1);
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.imageWidgetForm.validate()) {
        console.log("Validation passed");
        this.dialog = false;
        this.uploadImage();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px 0 30px;
      max-height: 80px;
      max-width: 80px;
    }
  }

  .select-folder-button {
    margin: 20px 0 0 !important;
  }

  .preview-list {
    border-bottom: 1px solid #DFE3E9;
  }

  .preview {
    display: block;
    margin: 0;
    padding: 15px 0 10px;
    border-top: 1px solid #DFE3E9;

    /deep/ .v-list__tile {
      padding: 0;
    }

    &__label {
      padding: 0;
      margin: 0 0 10px;
      font-size: 10px;
    }

    &__image {
      max-height: 56px;
      max-width: 56px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      display: block;
      margin: 0 0 0 30px;
      cursor: pointer;
    }
  }

  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }

  .tag-manager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      padding: 4px 15px;
      margin: 15px 5px 0 0;
      color: #fff;
      background-color: #B391BD;
      border-radius: 4px;
      cursor: pointer;
    }

    &__input {
      width: 100%;
    }
  }

  .image-widget {
    &__width {
      margin: 10px 0 30px;
      display: flex;
      justify-content: space-between;
      &-item {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        + label {
            cursor: pointer;
            text-align: center;
            &:before {
                content: '';
                width: 64px;
                height: 56px;
                display: block;
                cursor: pointer;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.5;
            }
            span {
              font-size: 0.75rem;
              color: $purple;
              margin-top: 5px;
              display: block;
              border-radius: 100px;
              padding: 3px 6px;
            }
        }
        &:checked {
          + label{
            &:before {
                opacity: 1.0;
            }
            span {
              background-color: #67247c;
              color: white;
            }
          }
        }
        &--standard {
          + label:before {
            background-image: url('../../assets/standard.svg');
          }
        }
        &--wide {
          + label:before {
            background-image: url('../../assets/wide.svg');
          }
        }
        &--full {
          + label:before {
            background-image: url('../../assets/full-width.svg');
          }
        }
      }
    }
  }

</style>
