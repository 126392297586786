<template>
  <div class="folder-row">
    <div @click="toggleShowChildren(directory.mediaDirectoryID)" class="folder-row__arrow">
      <v-icon v-if="isOpened === true" right>arrow_drop_down</v-icon>
      <v-icon v-else right>arrow_right</v-icon>
    </div>

    <div @click="selectDirectory(directory.mediaDirectoryID)" class="folder-name" :class="{ active: isActive }">
      <v-icon class="folder-name__icon">folder</v-icon>
      &nbsp;{{directory.directoryName}} <span v-if="directory.directories.length > 0 ">&nbsp;({{directory.directories.length}}) </span>
    </div>

    <MediaTreeFolderContents v-if="isOpened === true" :directories="directory.directories" @selectDirectory="selectDirectory($event)"/>
  </div>
</template>
<script>
export default {
  name: "MediaTreeFolder",

  components: {
    MediaTreeFolderContents: () => import("@/components/Media/MediaTreeFolderContents.vue")
  } ,

  props: {
    directory: {
      type: Object,
      required: true
    }
  },

  computed: {
    
    currentMediaDirectory: {
      get () {
        console.log("DIR", this.$store.state.currentMediaDirectory)
        return this.$store.state.currentMediaDirectory
      }
    }
  },
  watch: {
    currentMediaDirectory (mediaDirectoryID) {
      if(mediaDirectoryID === this.directory.mediaDirectoryID){
        this.isActive = true
      } else{
        this.isActive = false
      }
    }
  },

  data: () => ({
    isOpened: false,
    isActive: false
  }),

  mounted() {
    this.initialiseDirectoryState()
  },

  methods: {
    selectDirectory(mediaDirectoryID) {
      console.log("selectDirectory2", mediaDirectoryID)
      this.$emit("selectDirectory", mediaDirectoryID)
    },

    initialiseDirectoryState(){
      // load opened states
      if(localStorage.mediaLibraryOpened){
        var openedItems =  localStorage.mediaLibraryOpened.split(",")
        if(openedItems.includes(this.directory.mediaDirectoryID)) {
          this.isOpened = true
        }
      } else {
        localStorage.mediaLibraryOpened = ""
      }
    },
    toggleShowChildren(mediaDirectoryID){
      console.log("toggle children", mediaDirectoryID)
      var mediaLibraryOpened = localStorage.mediaLibraryOpened.split(",")
      if(mediaLibraryOpened.includes(mediaDirectoryID)){
        mediaLibraryOpened = mediaLibraryOpened.filter(function(value, index, arr){
            console.log(index, arr)
            return value != mediaDirectoryID;

        });
        this.isOpened = false
        localStorage.mediaLibraryOpened = mediaLibraryOpened
      } else {
        mediaLibraryOpened.push(mediaDirectoryID)
        localStorage.mediaLibraryOpened = mediaLibraryOpened
        this.isOpened = true
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .folder-name {
    position: relative;
    padding: 0 0 0 25px;

    &__icon {
      position: absolute;
      top: -2px;
      left: 0;
    }

    &.active .folder-name__icon {
      color: purple;
    }
  }

  .folder-icons{
    display: inline;
  }

  .folder-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    margin: 3px 0;
    padding: 0 0 0 25px;

    &__arrow {
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  .v-icon--right {
    margin-left: 1px; 
  }

  .active{
    color: purple;
  }
</style>