<template>
  <v-dialog
    class="folder-rename"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

    <v-card>
      <v-card-title>Rename folder</v-card-title>

      <v-card-text>
        <v-text-field :placeholder="directoryName" v-model="folderName"/>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          outline
          flat
          :disabled="!folderName"
          @click="renameDirectory()"
        >
          Rename folder
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "SubdirectoryRename",

  props: {
    mediaDirectoryID: {
      type: String,
      required: true
    },
    directoryName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/media`,
    dialog: false,
    folderName: ""
  }),

  methods: {
    renameDirectory() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/directory/rename`,
          headers: { "Authorization": jwt },
          data: {
            directoryName: this.folderName,
            mediaDirectoryID: this.mediaDirectoryID
          }
        })
        .then(response => {
          console.log("Folder renamed:", response);

          this.$emit("update")

          this.dialog = false
        })
        .catch(e => {
          console.error("Problem adding folder", e);
        });
      })
    },

  }
}
</script>

<style lang="scss" scoped>

</style>
