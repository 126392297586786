<template>
  <v-dialog
    class="subdirectory-select"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-card>
        <v-card-title>Select a folder</v-card-title>

        <v-card-text>
          <ol v-if="directories" class="directory-list directory-list--main">
            <li v-for="directory1 of directories" :key="directory1.mediaDirectoryID" class="directory-list__item">
              <span class="directory-list__button" :class="isSelected(directory1.mediaDirectoryID)" @click="chooseDirectory(directory1.mediaDirectoryID, directory1.directoryName)">
                <v-icon class="directory-list__icon">folder</v-icon>
                <span>{{ directory1.directoryName }}</span>
              </span>
              
              <ol v-if="directory1.directories.length" class="directory-list">
                <li v-for="directory2 of directory1.directories" :key="directory2.mediaDirectoryID" class="directory-list__item">
                  <span class="directory-list__button" :class="isSelected(directory2.mediaDirectoryID)" @click="chooseDirectory(directory2.mediaDirectoryID, directory2.directoryName)">
                    <v-icon class="directory-list__icon">folder</v-icon>
                    <span>{{ directory2.directoryName }}</span>
                  </span>

                  <ol v-if="directory2.directories.length" class="directory-list">
                    <li v-for="directory3 of directory2.directories" :key="directory3.mediaDirectoryID" class="directory-list__item">
                      <span class="directory-list__button" :class="isSelected(directory3.mediaDirectoryID)" @click="chooseDirectory(directory3.mediaDirectoryID, directory3.directoryName)">
                        <v-icon class="directory-list__icon">folder</v-icon>
                        <span>{{ directory3.directoryName }}</span>
                      </span>

                      <ol v-if="directory3.directories.length" class="directory-list">
                        <li v-for="directory4 of directory3.directories" :key="directory4.mediaDirectoryID" class="directory-list__item">
                          <span class="directory-list__button" :class="isSelected(directory4.mediaDirectoryID)" @click="chooseDirectory(directory4.mediaDirectoryID, directory4.directoryName)">
                            <v-icon class="directory-list__icon">folder</v-icon>
                            <span>{{ directory4.directoryName }}</span>
                          </span>

                          <ol v-if="directory4.directories.length" class="directory-list">
                            <li v-for="directory5 of directory4.directories" :key="directory5.mediaDirectoryID" class="directory-list__item">
                              <span class="directory-list__button" :class="isSelected(directory5.mediaDirectoryID)" @click="chooseDirectory(directory5.mediaDirectoryID, directory5.directoryName)">
                                <v-icon class="directory-list__icon">folder</v-icon>
                                <span>{{ directory5.directoryName }}</span>
                              </span>

                              <ol v-if="directory5.directories.length" class="directory-list">
                                <li v-for="directory6 of directory5.directories" :key="directory6.mediaDirectoryID" class="directory-list__item">
                                  <span class="directory-list__button" :class="isSelected(directory6.mediaDirectoryID)" @click="chooseDirectory(directory6.mediaDirectoryID, directory6.directoryName)">
                                    <v-icon class="directory-list__icon">folder</v-icon>
                                    <span>{{ directory6.directoryName }}</span>
                                  </span>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            @click="selectSubdirectory()"
          >
            Select folder
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "SubdirectorySelector",

  data: () => ({
    dialog: false,
    api: `${process.env.VUE_APP_BASEURL}/media`,
    selectedDirectoryID: "",
    selectedDirectoryName: "",
    directories: []
  }),

  watch: {
    dialog: function(val) {
      console.log("Dialog val changed: ", val)
      if (val) {
        this.directories = []
        this.fetchDirectories()
      }
    }
  },

  methods: {
    chooseDirectory(mediaLibraryID, directoryName) {
      console.log("chooseDirectory", directoryName)
      this.selectedDirectoryID = mediaLibraryID
      this.selectedDirectoryName = directoryName
    },

    selectSubdirectory() {
      console.log("this.selectedDirectoryID", this.selectedDirectoryID)

      this.$emit("changeDirectory", {
        directoryID: this.selectedDirectoryID,
        directoryName: this.selectedDirectoryName
      })

      setTimeout(() => {
        this.dialog = false
      }, 100)
    },

    fetchDirectories() {  
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/directories/`, { "headers": { "Authorization": jwt }})
        .then(response => {
          this.directories = response.data.directories
        })
        .catch(e => {
          console.error("Problem retrieving directories", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    isSelected(itemID) {
      if (itemID === this.selectedDirectoryID) {
        return "is-selected"
      } else {
        return ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .directory-list {
    padding: 0 7px;
    margin: 0 0 0 7px;
    border-left: 1px solid #67247c;

    &--main {
      padding: 0;
      margin: 0;
      height: 100%;
      border: 0;
    }

    &__button {
      display: block;
      cursor: pointer;
    }

    &__item {
      padding: 3px 0;
      color: #67247c;
      font-size: 14px;
      font-weight: 500;
    }

    &__button {
      opacity: 0.6;

      &.is-selected {
        opacity: 1;
      }
    }

    &__icon {
      padding: 0 4px 0 0;
      color: #67247c;
      font-size: 18px;
    }
  }
</style>
