<template>
  <v-dialog
    class="folder-selector"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-card>
        <v-card-title>Select a folder</v-card-title>

        <v-card-text>
          <div dense v-for="directory in directories" :key="directory.mediaDirectoryID">
            <MediaTreeFolder :directory="directory" @selectDirectory="setDirectoryDestination($event)"/>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            :disabled="!directoryDestination"
            @click="move()"
          >
            Set Folder
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import MediaTreeFolder from "@/components/Media/MediaTreeFolder.vue"

export default {
  name: "FolderSelector",

  components: {
    MediaTreeFolder
  },

  data: () => ({
    dialog: false,
    api: `${process.env.VUE_APP_BASEURL}/media`,
    directories: [],
    directoryDestination: null
  }),

  mounted() {
    this.listDirectories()
  },

  methods: {
    move() {
      this.$emit("moveItems", this.directoryDestination)
      this.dialog = false
    },

    setDirectoryDestination(mediaDirectoryID){
      console.log("setDirectoryDestination", mediaDirectoryID)
      this.$store.commit("saveCurrentMediaDirectory", mediaDirectoryID)
      this.directoryDestination = mediaDirectoryID
    },

    listDirectories(){
      
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/directories/`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("directories", response)
          this.directories = response.data.directories
        })
        .catch(e => {
          console.error("Problem retrieving pages", e);
          this.$store.commit("completeLoading");
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
