<template>
  <section class="media-mover">
    <div class="media-mover__group-label">
      <p class="media-mover__label">{{ $store.state.mediaLibrary.multiSelectedImages.length }} <span>file(s) selected</span></p>
    </div>

    <div class="media-mover__location-group">
      <p v-if="targetDirectoryID" class="media-mover__location">Adding to <span class="media-mover__location-folder"><v-icon class="media-mover__location-icon">folder</v-icon>{{ targetDirectoryName }}</span></p>

      <p v-else>Please select a folder</p>

      <subdirectory-selector @changeDirectory="updateDirectory($event)">
        <button class="media-mover__folder"><v-icon class="media-mover__folder-icon">perm_media</v-icon>Change folder</button>
      </subdirectory-selector>
    </div>

    <div>
      <v-btn @click="cancel()" class="media-mover__cancel">Cancel</v-btn>
      <v-btn :disabled="!targetDirectoryID" @click="moveItems()" color="accent" class="media-mover__primary">Move to folder</v-btn>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import SubdirectorySelector from "@/components/Media/V2/SubdirectorySelector.vue"

export default {
  name: "MediaMover",

  components: {
    SubdirectorySelector
  },

  data:() => ({
    targetDirectoryID: null,
    targetDirectoryName: null
  }),

  methods: {
    updateDirectory(payload) {
      console.log("updateDirectory", payload)
      this.targetDirectoryID = payload.directoryID
      this.targetDirectoryName = payload.directoryName
    },

    moveItems() {
      console.log("Going to move items to ", this.targetDirectoryID)

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/directory/move-items`,
          headers: { "Authorization": jwt },
          data: {
            mediaItems: this.$store.state.mediaLibrary.multiSelectedImages,
            mediaDirectoryID: this.targetDirectoryID
          }
        })
        .then(response => {
          console.log("Items moved:", response);
          this.$emit("update")
          this.$store.commit("multiSelectImageClearAll")
        })
        .catch(e => {
          console.error("Problem moving items", e);
          alert("There was a problem moving the items")
        });
      })
    },

    cancel() {
      this.$store.commit("multiSelectImageClearAll")
    }
  }
}
</script>

<style scoped lang="scss">
  .media-mover {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 26px;
    z-index: 10;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);

    &__label {
      margin: 0 16px 0 0;
      padding: 0;
      color: #24002f;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: left;

      span {
        font-weight: 400;
      }

      &--error {
        color: #f42323;
      }
    }

    &__folder {
      padding: 0;
      margin: 0 16px;
      opacity: 0.5;
      color: #516173;
      font-family: 'Rubik', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }

    &__folder-icon {
      position: relative;
      top: 2px;
      padding: 0 4px 0 0;
    }

    &__location-group {
      text-align: center;
    }

    &__location {
      margin: 0 0 10px;
      padding: 0;
      color: #516173;
      font-family: 'Rubik', sans-serif;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
      opacity: 0.5;
    }

    &__location-folder {
      border-bottom: 1px solid;
      padding: 3px;
      border-radius: 4px;
    }

    &__location-icon {
      padding: 0 3px 0 0;
      color: #516173;
      font-size: 16px;
    }   
  }
</style>
