<template>
  <article class="subfolder create-folder">
    <v-icon class="subfolder__icon">create_new_folder</v-icon>
    <p class="subfolder__name">New Folder</p>
  </article>
</template>

<script>
export default {
  name: "SubdirectoryNew"
}
</script>

<style scoped lang="scss">
  .subfolder {
    position: relative;
    margin: 0 0 24px 16px;
    width: 160px;

    &__icon {
      width: 160px;
      height: 120px;
      padding: 50px;
      margin: 0 0 5px;
      color: #67247c;
      font-size: 80px;
      text-align: center;
      border-radius: 4px;
      background-color: #fafafa;
    }

    &__name {
      margin: 4px auto 0 0;
      padding-right: 4px;
      color: #24002f;
      font-size: 12px;
      font-weight: 400;
    }

    &__quick-edit {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;
      margin: 0;
      color: #000;
      font-size: 14px;
      background: transparent;
      border: 0;
      z-index: 2;
    }
  }
</style>
